import { DataAccess } from "../dataaccess/data.access";
import { S25Util } from "../util/s25-util";
import { Cache, Invalidate } from "../decorators/cache.decorator";
import { LooseAutocomplete, ValueOf } from "../pojo/Util";
import { UserprefService } from "./userpref.service";
import { PreferenceService } from "./preference.service";
import { FlsService } from "./fls.service";
import { Rule, RuleTreeService } from "./rule.tree.service";
import { Timeout } from "../decorators/timeout.decorator";
import { Proto } from "../pojo/Proto";
import NumericalBoolean = Proto.NumericalBoolean;

const CONFIG2UUID = {
    name: { uuid: "name", display: "Event Name" },
    title: { uuid: "title", display: "Event Title" },
    type: { uuid: "type", display: "Event Type" },
    sponsor: { uuid: "primaryOrg", display: "Primary Organization" },
    additional_sponsor: { uuid: "otherOrgs", display: "Additional Organizations" },
    headcount: { uuid: "registered", display: "Registered Head Count", deprecated: true },
    headcount_expected: { uuid: "expected", display: "Expected Head Count" },
    headcount_registered: { uuid: "registered", display: "Registered Head Count" },
    description_html: { uuid: "description", display: "Event Description" },
    description: { uuid: "description", display: "Event Description", deprecated: true },
    datetime: { uuid: "occurrences", display: "Date and Time" },
    file_attach: { uuid: "files", display: "Attached Files" },
    ev_custom_attr: { uuid: "customAttributes", display: "Custom Attributes" },
    contact: { uuid: "contacts", display: "Event Contacts" },
    ev_category: { uuid: "categories", display: "Categories" },
    requirement_other: { uuid: "requirements", display: "Requirements" },
    comment: { uuid: "comment", display: "Comments" },
    confirm_text: { uuid: "confirmationNote", display: "Confirmation Notes" },
    notes: { uuid: "internalNote", display: "Internal Notes" },
    state: { uuid: "state", display: "Event State" },
    requirement: { uuid: "publishToCalendar", display: "Publish to Calendar" },
    affirmation: { uuid: "affirmation", display: "Affirmation" },
    space: { uuid: "space", display: "Locations" },
    resource: { uuid: "resource", display: "Resources" },
    createAndRelate: { uuid: "createAndRelate", display: "Post-Save" },
} as const;

const UUIDExt = {
    name: { order: 0, max: 40 },
    title: { order: 1, max: 120 },
    type: { order: 2 },
    primaryOrg: { order: 3 },
    otherOrgs: { order: 4 },
    expected: { order: 5, min: 0, max: 100000 },
    registered: { order: 6, min: 0, max: 100000 },
    description: { order: 7, max: 32000 },
    occurrences: { order: 8 },
    space: { order: 8.1 },
    resource: { order: 8.2 },
    files: { order: 9 },
    customAttributes: { order: 10 },
    contacts: { order: 11 },
    categories: { order: 12 },
    requirements: { order: 13 },
    comment: { order: 14, max: 32000 },
    confirmationNote: { order: 15, max: 32000 },
    internalNote: { order: 16, max: 32000 },
    state: { order: 17 },
    publishToCalendar: { order: 13.1 },
    affirmation: { order: 19 },
    createAndRelate: { order: 10000, display: "Post-Save", noOutsideLabel: true, primitiveItem: false },
} as const;

const ConfigFormItemData = {
    name: { display: "Event Name", order: 1, uuid: "name" },
    title: { display: "Event Title", order: 2, uuid: "title" },
    type: { display: "Event Type", order: 3, uuid: "type" },
    sponsor: { display: "Primary Organization", order: 4, uuid: "primaryOrg" },
    additional_sponsor: { display: "Additional Organizations", order: 5, uuid: "otherOrgs" },
    headcount_expected: { display: "Expected Head Count", order: 6, uuid: "expected" },
    headcount: { display: "Registered Head Count", order: 7, deprecated: true, uuid: "registered" },
    headcount_registered: { display: "Registered Head Count", order: 7, uuid: "registered" },
    description_html: { display: "Event Description", order: 8, uuid: "description" },
    description: { display: "Event Description", order: 8, deprecated: true, uuid: "description" },
    datetime: { display: "Date and Time", order: 9, uuid: "occurrences" },
    space: { display: "Locations", order: 10, uuid: "space" },
    resource: { display: "Resources", order: 11, uuid: "resource" },
    file_attach: { display: "Attached Files", order: 12, uuid: "files" },
    ev_custom_attr: { display: "Custom Attributes", order: 13, uuid: "customAttributes" },
    contact: { display: "Event Contacts", order: 14, uuid: "contacts" },
    ev_category: { display: "Categories", order: 15, uuid: "categories" },
    requirement_other: { display: "Requirements", order: 16, uuid: "requirements" },
    requirement: { display: "Publish to Calendar", order: 17, uuid: "publishToCalendar" },
    comment: { display: "Comments", order: 18, uuid: "comment" },
    confirm_text: { display: "Confirmation Notes", order: 19, uuid: "confirmationNote" },
    notes: { display: "Internal Notes", order: 20, uuid: "internalNote" },
    state: { display: "Event State", order: 21, uuid: "state" },
    affirmation: { display: "Affirmation", order: 22, uuid: "affirmation" },
} as const;

export class EventCreationFormService {
    public static CONFIG2UUID = CONFIG2UUID;
    public static UUIDExt = UUIDExt;
    public static ConfigFormItemData = ConfigFormItemData;

    public static configItemToModel(
        mode: LooseAutocomplete<"create">,
        item: EventFormConfigItem,
        forceHidden?: boolean,
    ) {
        if (item) {
            let uuid =
                EventCreationFormService.CONFIG2UUID[item.item_name] &&
                EventCreationFormService.CONFIG2UUID[item.item_name].uuid;
            if (uuid) {
                let extensions = EventCreationFormService.UUIDExt[uuid];

                let required = !!item.required;
                let editable = !item.no_edit;

                if (mode === "create") {
                    editable = editable || required; //for create mode, if something is required, it must be editable
                }

                let itemModel = {
                    uuid: uuid,
                    messageOpen: parseInt(item.message_open) === 1,
                    display:
                        (extensions && "display" in extensions) ||
                        item.item_display ||
                        EventCreationFormService.CONFIG2UUID[item.item_name].display,
                    noOutsideLabel:
                        (extensions && "noOutsideLabel" in extensions && extensions.noOutsideLabel) ||
                        item.noOutsideLabel,
                    required: required,
                    editable: editable,
                    message: item.item_message,
                    inlineMessage: ["affirmation"].indexOf(uuid) > -1,
                    isInit:
                        ["customAttributes", "contacts", "categories", "requirements", "publishToCalendar"].indexOf(
                            uuid,
                        ) === -1,
                    order: S25Util.coalesce(item.sort_order, extensions && extensions.order),
                    min: extensions && "min" in extensions ? extensions.min : undefined,
                    max: extensions && "max" in extensions ? extensions.max : undefined,
                    primitiveItem: extensions && "primitiveItem" in extensions ? extensions.primitiveItem : true,
                    primitiveItemName: item.item_name,
                    forceHidden: !!forceHidden,
                    onProfile:
                        ["expected", "registered", "occurrences", "space", "resource", "comment"].indexOf(uuid) > -1,
                } as any;

                switch (itemModel.uuid) {
                    case "type":
                        itemModel.onComplete = [
                            {
                                action: "refresh",
                                targets: [
                                    "customAttributes",
                                    "contacts",
                                    "categories",
                                    "requirements",
                                    "publishToCalendar",
                                ],
                            },
                            { action: "doActionIfRuleSatisfied", timeout: true, runModelFunc: "runRules" },
                        ];
                        break;
                    case "primaryOrg":
                        itemModel.onComplete = [
                            { action: "refreshIfInit", targets: ["contacts"] },
                            { action: "doActionIfRuleSatisfied", timeout: true, runModelFunc: "runRules" },
                        ];
                        break;
                    case "customAttributes":
                    case "space":
                    case "resource":
                    case "categories":
                    case "requirements":
                    case "publishToCalendar":
                    case "expected":
                    case "registered":
                    case "occurrences":
                    case "contacts":
                    case "otherOrgs":
                        itemModel.onComplete = {
                            action: "doActionIfRuleSatisfied",
                            timeout: true,
                            runModelFunc: "runRules",
                        };
                        break;
                }

                return itemModel;
            }
        }
    }

    public static _getModelData(url: string) {
        return DataAccess.get(url);
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventCreationFormService" })
    public static _getModel(mode: LooseAutocomplete<"create">, configIdOverride: number) {
        let url = "/wizard/config.json?mode=" + mode;
        if (S25Util.isDefined(configIdOverride)) {
            url += "&itemId=" + configIdOverride;
        }
        return this._getModelData(url).then(function (data) {
            let model: any = {};
            if (data && data.config) {
                model.mode = mode;
                model.configuration_id = data.config.configuration_id;
                model.configuration_name = data.config.configuration_name;
                model.message = data.config.configuration_message;
                model.isDefault = S25Util.toBool(data.config.is_default);
                model.autoAddCustAtrb = S25Util.toBool(S25Util.coalesce(data.config.auto_add_cust_atrb, false));
                model.hasSpansMidnight = S25Util.toBool(S25Util.coalesce(data.config.has_spans_midnight, true));
                model.hasAllDay = S25Util.toBool(S25Util.coalesce(data.config.has_all_day, false));
                model.hasMultiProfilePerm = S25Util.toBool(S25Util.coalesce(data.config.has_multi_profile, false));
                model.hasRecommendedResource = S25Util.toBool(
                    S25Util.coalesce(data.config.has_recommended_resource, false),
                );
                model.hasRecommendedLocation = S25Util.toBool(
                    S25Util.coalesce(data.config.has_recommended_location, false),
                );
                model.hasSilentSave = S25Util.toBool(S25Util.coalesce(data.config.has_silent_save, false));
                model.defaultEventType = data.config.default_event_type;
                model.hasPattern = S25Util.toBool(S25Util.coalesce(data.config.has_pattern, true));
                model.patternHelp = S25Util.toStr(data.config.pattern_help);
                model.disableRules = S25Util.toBool(S25Util.coalesce(data.config.disable_rules, false));
                model.optionalAttributes = S25Util.toBool(S25Util.coalesce(data.config.optional_attributes, false));
                model.items = [];

                const visibleItems: Set<ValueOf<typeof CONFIG2UUID>["uuid"]> = new Set();
                for (let item of data.config.item) {
                    let itemModel = EventCreationFormService.configItemToModel(mode, item);
                    if (itemModel?.uuid) {
                        model.items.push(itemModel);
                        visibleItems.add(itemModel.uuid);
                    }
                }

                const uuidToName: Map<ValueOf<typeof CONFIG2UUID>["uuid"], keyof typeof CONFIG2UUID> = new Map();
                for (let [key, conf] of Object.entries(CONFIG2UUID)) {
                    if ("deprecated" in conf && conf.deprecated) continue; // Ignore deprecated for the map
                    uuidToName.set(conf.uuid, key as keyof typeof CONFIG2UUID);
                }

                //always have these nodes, but if not in form model, make it always hidden so users never actually see it
                // This is necessary in particular for rules which we expect to add targets even when the item is hidden
                //hidden via: forceHidden: true (forceHidden = true)
                const alwaysPresent: ValueOf<typeof CONFIG2UUID>["uuid"][] = [
                    "contacts",
                    "requirements",
                    "publishToCalendar",
                    "categories",
                    "occurrences",
                    "type",
                    "primaryOrg",
                    "otherOrgs",
                    "resource",
                    "state",
                ];
                for (let uuid of alwaysPresent) {
                    if (visibleItems.has(uuid)) continue;
                    const item_name = uuidToName.get(uuid);
                    model.items.push(EventCreationFormService.configItemToModel(mode, { item_name }, true));
                }

                return model;
            }
        });
    }

    @Timeout
    public static getModel(mode: LooseAutocomplete<"create">, configIdOverride: number): Promise<EventFormConfigModel> {
        return S25Util.all({
            minCreateDate: UserprefService.getEarliestEventCreationDate(),
            maxCreateDate: UserprefService.getLatestEventCreationDate(),
            timeFormat: UserprefService.getS25Timeformat(),
            dateFormat: UserprefService.getS25Dateformat(),
            dateTimeFormat: UserprefService.getS25DateTimeformat(),
            sec: UserprefService.getUserGroupRoseSecurity(),
            groupId: UserprefService.getGroupId(),
            allowedStates: UserprefService.getAllowedStates(),
            model: EventCreationFormService._getModel(mode, configIdOverride),
            prefs: PreferenceService.getPreferences(["SpbkEvState", "auto_load_starred_sp", "auto_load_starred_rs"]),
            fls: FlsService.getFls(),
            rules: RuleTreeService.getRules("form"),
            validationRules: RuleTreeService.getRules("formValidation"),
        }).then(function (resp) {
            if (resp && resp.model && resp.sec) {
                let allowedStates = [].concat(resp.allowedStates);
                if (mode === "create") {
                    allowedStates = allowedStates.filter(function (stateId) {
                        return [3, 98, 99].indexOf(stateId) === -1; //if create mode, do NOT allow sealed, denied, cancelled
                    });
                }

                const allowedOrgs =
                    resp.sec.organizations_allowed?.length || resp.sec.org_roles_allowed?.length
                        ? { allowedOrgs: resp.sec.organizations_allowed, allowedRoles: resp.sec.org_roles_allowed }
                        : null;

                let model = S25Util.extend(resp.model, {
                    defaultState:
                        resp.prefs &&
                        resp.prefs.SpbkEvState &&
                        S25Util.coalesce(parseInt(resp.prefs.SpbkEvState.value), 1),
                    autoLoadStarredSp:
                        resp.prefs &&
                        resp.prefs.auto_load_starred_sp &&
                        S25Util.toBool(S25Util.coalesce(resp.prefs.auto_load_starred_sp.value, false)),
                    autoLoadStarredRs:
                        resp.prefs &&
                        resp.prefs.auto_load_starred_rs &&
                        S25Util.toBool(S25Util.coalesce(resp.prefs.auto_load_starred_rs.value, false)),
                    useLocationScheduler: S25Util.toBool(S25Util.propertyGet(resp.sec, "use_location_scheduler")),
                    suppressedDatetimeFields: (S25Util.propertyGet(resp.sec, "suppressed_datetime_fields") || "").split(
                        /\s*,\s*/,
                    ),
                    defaultSchedulerId: parseInt(S25Util.propertyGet(resp.sec, "default_scheduler_id")) || null,
                    successMsg: (mode === "edit" ? resp.sec.edit_success_msg : resp.sec.creation_success_msg) || "",
                    minCreateDate: resp.minCreateDate,
                    maxCreateDate: resp.maxCreateDate,
                    timeFormat: resp.timeFormat,
                    dateFormat: resp.dateFormat,
                    dateTimeFormat: resp.dateTimeFormat,
                    rules: resp.rules,
                    validationRules: resp.validationRules,
                    groupId: resp.groupId,
                    allowedOrgs: allowedOrgs,
                });

                //add create org btn
                let createOrgBtn = {
                    uuid: "createOrgBtn",
                    isInit: true,
                    derivedItem: true,
                    editable: true,
                    primitiveItem: false,
                };
                const additionalOrgs = model.items.find((item: any) => item.uuid === "otherOrgs");
                const primaryOrg = model.items.find((item: any) => item.uuid === "primaryOrg");
                //favor placing after other orgs, which is further down the form
                //else place after primary org if other orgs not present but primary is
                if (additionalOrgs && !additionalOrgs.forceHidden) {
                    model.items.splice(0, 0, S25Util.extend(createOrgBtn, { order: additionalOrgs.order + 0.1 })); //add create org at proper place
                } else if (primaryOrg && !primaryOrg.forceHidden) {
                    model.items.splice(0, 0, S25Util.extend(createOrgBtn, { order: primaryOrg.order + 0.1 })); //add create org at proper place
                }

                //add createAndRelate checkbox
                //acceptable edit ols implied by event container perms; logged-in implied by framework state perms; so we just check FLS
                if (resp.fls.EVENT_ACTION === "F" && !S25Util.isInIframe) {
                    var createAndRelate = S25Util.deepCopy(EventCreationFormService.UUIDExt.createAndRelate);
                    model.items.push(
                        S25Util.extend(createAndRelate, {
                            uuid: "createAndRelate",
                            isInit: true,
                            data: "goToEventDetails",
                            editable: true,
                        }),
                    );
                }

                //set default state to first allowed state for event creation when the original default state is NOT allowed
                if (mode === "create" && allowedStates.indexOf(model.defaultState) === -1) {
                    if (allowedStates.length) {
                        model.defaultState = allowedStates[0];
                    }
                }

                //some items are also only editable if certain FLS rights are F (ANG-1871)
                let itemFlsMap: any = {
                    description: { fls: "EVENT_TEXT", access: ["F"] },
                    comment: { fls: "EVENT_TEXT", access: ["F"] },
                    confirmationNote: { fls: "EVENT_TEXT", access: ["F"] },
                    internalNote: { fls: "EVENT_NOTES", access: ["F"] },
                    state: { fls: "EVENT_STATE", access: ["F", "C"] },
                };

                //set editable based on FLS and also remove if no FLS rights
                for (let i = model.items.length - 1; i >= 0; i--) {
                    let item = model.items[i];
                    if (itemFlsMap[item.uuid]) {
                        //if item is controlled by FLS
                        let flsAccess = resp.fls[itemFlsMap[item.uuid].fls]; //get FLS right access
                        item.editable = item.editable && itemFlsMap[item.uuid].access.indexOf(flsAccess) > -1; //editable is the AND of itself and the fls right

                        if (flsAccess === "N") {
                            //if the fls is N, we should remove it completely
                            model.items.splice(i, 1);
                        }
                    }
                }

                model.items.sort(S25Util.shallowSort("order", true));

                //set up item dictionary for lookups
                model.itemDict = {};
                for (let item of model.items) {
                    model.itemDict[item.uuid] = item;
                }

                //set defaults (hydration will override if editing/copying event)
                if (model.itemDict.state) {
                    model.itemDict.state.data = model.defaultState;
                }
                if (model.itemDict.type && model.defaultEventType) {
                    model.itemDict.type.data = S25Util.deepCopy(model.defaultEventType);
                }

                model.canSearchLocations =
                    (["F", "R", "C"].indexOf(resp.fls.SPACE_LIST) > -1 || resp.fls.SPACE_PERM === "F") &&
                    resp.fls.SPACE_SEARCH !== "N";
                model.hasLocationSearch = model.canSearchLocations && model.itemDict.space;

                model.canSearchResources =
                    (["F", "R", "C"].indexOf(resp.fls.RESOURCE_LIST) > -1 || resp.fls.RESOURCE_PERM === "F") &&
                    resp.fls.RESOURCE_SEARCH !== "N";
                model.hasResourceSearch = model.canSearchResources && model.itemDict.resource;

                model.hasSearch = model.hasLocationSearch || model.hasResourceSearch;

                return model;
            } else {
                var msg = "Errors: ",
                    errors = [];
                !resp.model && errors.push("Could not load the data model for the form");
                !resp.sec && errors.push("Could not load the security model for the form");
                msg += errors.join(", ");
                alert(msg);
                return null;
            }
        });
    }

    @Timeout
    public static _getAllConfigs(): Promise<{ config: EventFormConfigData[] }> {
        return DataAccess.get("/wizard/all/configs.json");
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventCreationFormService" })
    public static async getAllConfigs() {
        const data = await EventCreationFormService._getAllConfigs();
        const configs = data?.config || [];
        configs.sort(S25Util.shallowSort("itemName"));
        for (let config of configs) {
            if (S25Util.toBool(config.is_default)) {
                config.itemName = S25Util.toStr(config.itemName) + " (Default)";
            }
        }
        return configs;
    }

    @Timeout
    @Invalidate({ serviceName: "EventCreationFormService" })
    public static putConfig(config: EventFormConfigPutQuery, configId: string | number) {
        return DataAccess.put(
            "/wizard/config.json" + (S25Util.isDefined(configId) ? "?itemId=" + configId : ""),
            JSON.stringify(config),
        );
    }

    @Timeout
    @Invalidate({ serviceName: "EventCreationFormService" })
    public static delConfig(configId: string | number) {
        return DataAccess.delete("/wizard/config.json" + (S25Util.isDefined(configId) ? "?itemId=" + configId : ""));
    }

    @Timeout
    @Cache({ immutable: true, targetName: "EventCreationFormService" })
    public static async getConfig(id: number): Promise<EventFormConfigResponse> {
        const data = await DataAccess.get(
            DataAccess.injectCaller(
                `/wizard/config.json?itemId=${id}&include=hidden`,
                "EventCreationFormService.getConfig",
            ),
        );
        return data.config;
    }
}

export interface EventFormConfigPutQuery {
    root: {
        config: Omit<EventFormConfigResponse, "item" | "pref_name" | "instance_id">;
        item: EventFormConfigResponseItem[];
    };
}

export interface EventFormConfigResponse {
    configuration_message: string;
    item: EventFormConfigResponseItem[];
    has_recommended_resource: NumericalBoolean;
    has_recommended_location: NumericalBoolean;
    has_pattern: NumericalBoolean;
    is_default: NumericalBoolean;
    has_multi_profile: NumericalBoolean;
    pref_name: string;
    instance_id: string;
    has_silent_save: NumericalBoolean;
    pattern_help: string;
    has_spans_midnight: NumericalBoolean;
    auto_add_cust_atrb: NumericalBoolean;
    configuration_id: number;
    configuration_name: string;
    has_all_day: NumericalBoolean;
    disable_rules: NumericalBoolean;
    optional_attributes: NumericalBoolean;
    default_event_type?: {
        itemId: number;
        itemName: string;
    };
}

export interface EventFormConfigResponseItem {
    message_open: NumericalBoolean;
    item_display: string;
    no_edit: NumericalBoolean;
    item_name: keyof typeof ConfigFormItemData;
    required: NumericalBoolean;
    item_message?: string;
    sort_order?: number;
    visible: NumericalBoolean;
}

export interface EventFormConfigItem {
    item_name: keyof typeof CONFIG2UUID;
    required?: boolean;
    no_edit?: boolean;
    item_display?: string;
    noOutsideLabel?: boolean;
    sort_order?: number;
    item_message?: string;
    message_open?: string;
}

export interface EventFormConfigData {
    itemId: number;
    itemName: string;
    instance_id: string;
    is_default: number;
    pref_name: string;
    help_message?: string;
    default_event_type_id?: number;
    default_event_type_name?: string;
}

export interface EventFormConfigModel {
    autoAddCustAtrb: boolean;
    autoLoadStarredRs: boolean;
    autoLoadStarredSp: boolean;
    canSearchLocations: boolean;
    canSearchResources: boolean;
    configuration_id: number;
    configuration_name: string;
    dateFormat: string;
    dateTimeFormat: string;
    defaultEventType: any;
    defaultSchedulerId: number;
    defaultState: number;
    groupId: number;
    hasAllDay: boolean;
    hasRecommendedLocation: boolean;
    hasLocationSearch: HasSearch;
    hasMultiProfilePerm: boolean;
    hasPattern: boolean;
    hasRecommendedResource: boolean;
    hasResourceSearch: HasSearch;
    hasSearch: HasSearch;
    hasSilentSave: boolean;
    hasSpansMidnight: boolean;
    isDefault: boolean;
    itemDict: Record<string, HasSearch>;
    items: HasSearch[];
    maxCreateDate: Date;
    minCreateDate: Date;
    message: string;
    mode: LooseAutocomplete<"create">;
    patternHelp: string;
    rules: Rule[];
    validationRules: Rule[];
    successMsg: string;
    suppressedDatetimeFields: string[];
    timeFormat: string;
    useLocationScheduler: boolean;
}

interface HasSearch {
    display: string;
    editable: boolean;
    forceHidden: boolean;
    inlineMessage: boolean;
    isInit: boolean;
    max: boolean;
    message: string;
    messageOpen: boolean;
    min: boolean;
    noOutsideLabel: boolean;
    onComplete?: {
        action: string;
        runModelFunc: string;
        timeout: boolean;
    };
    onProfile: boolean;
    order: number;
    primitiveItem: boolean;
    primitiveItemName: string;
    required: boolean;
    uuid: string;
}
