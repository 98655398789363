import { S25Util } from "../util/s25-util";
import { DataAccess } from "../dataaccess/data.access";

export class EventProfileMicroService {
    public static updateProfileMinimal(eventId: number, profileId: number, data: ProfileMinimalI) {
        return DataAccess.put(
            DataAccess.injectCaller(
                "/micro/event/" + eventId + "/profile/" + profileId + "/detail.json",
                "EventProfileMicroService.updateProfileMinimal",
            ),
            {
                content: {
                    data: data,
                },
            },
        );
    }
}

export interface ProfileMinimalI {
    name?: string;
    expectedCount?: number | null;
    registeredCount?: number | null;
    comments?: string;
}
